<template>
    <div class="hometop-container">
        <div class="hometop-header">
            <span class="header-title  animate__animated animate__fadeInLeft ">公司介绍</span>
            <div class="header-text-container  animate__animated animate__fadeIn">
                <span class="header-container">云南航齐航空科技有限公司成立于2017年，是一家致力于RFID识别技术应用于
                智能工具管理系统研发、集成的专业化公司，公司目前取得专利证书多项，并在RFID技术应用的基础上不断的研发拓展，推出智能工具管理车、智能管理库
                房及相关智能RFID技术的移动设备、无人库房管理系统等定制化产品。</span>
            </div>
        </div>
        <div class="lianxi-box">
            <my-lianxi></my-lianxi>
        </div>
        <div class="body-container">
            <!-- <div class="body-box">
                <span class="header-title  animate__animated animate__fadeInLeft"><strong>success</strong>  cases</span>
               <div class="advantage"></div>
            </div> -->
            <div class="body-box">
                <span class="header-title  animate__animated animate__fadeInLeft">专利证书</span>
                <div class="goods_zy">
                    <div class="goods_zy_w">
                        <el-carousel :interval="2000" type="card" height="300px" width="200px">
                        <el-carousel-item v-for="item in images" :key="item.id">
                            <img class="tu" :src="item.tu" alt="">
                        </el-carousel-item>
                    </el-carousel>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>
<script> 
import MyLianxi from './MyLianxi.vue';
 
export default {
    name: 'HomeTop',
    data() {
        return {
            images: [
                {id: 1, tu: require('../images/tims_new/Ctf01.png')},
                {id: 2, tu: require('../images/tims_new/Ctf02.png')},
                {id: 3, tu: require('../images/tims_new/Ctf03.png')},
                {id: 4, tu: require('../images/tims_new/Ctf04.png')},
                {id: 5, tu: require('../images/tims_new/Ctf05.png')},
                {id: 6, tu: require('../images/tims_new/Ctf06.png')},
                {id: 7, tu: require('../images/tims_new/Ctf07.png')},
                {id: 8, tu: require('../images/tims_new/Ctf08.png')},
                {id: 9, tu: require('../images/tims_new/Ctf09.png')},
                {id: 10, tu: require('../images/tims_new/Ctf10.png')},
                {id: 11, tu: require('../images/tims_new/Ctf11.png')},
                {id: 12, tu: require('../images/tims_new/Ctf12.png')},
            ]
        }
    },
    components: {
        MyLianxi,
    }
}
</script>
<style>


.hometop-container {
    width: 100%;
    height: 100%;
}


.hometop-header {
    width: calc(100vw - 480px);
    height: 100%;
    margin: 0 240px;
}


.header-title {
    padding: 20px 0px 0px 0px;
    display: block;
    width: 100%;
    height: 100px;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    line-height: 100px;
    text-transform: uppercase;
}


.cn-text {
    text-align: center;
    font-size: 1rem;
    letter-spacing: 5px;
    color: #333;
    background-color: #cccccc3a;
    box-shadow: rgba(0, 0, 0, 0.644);
    border-radius: 20px;
    width: 200px;
    height: 50px;
    line-height: 50px;
}


span {
    display: block;
}


.header-container {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: 600;
}


.header-text-container {
    width: 100%;
    height: 100px;
    padding: 28px 0px;
}


.body-container {
    width: 100%;
    height: 100%;
    padding-bottom: 48px;
}


.body-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;

}


.body-center {
    width: 100%;
    height: 100%;

}


strong {
    color: #81b6ea;
}


.un-cp-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}


.un-cp-img {
    width: 100%;
    height: auto;
}


.header-title-small {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    font-size: 1.5rem;
    margin:-70px 0px auto;
    letter-spacing: 2px;
}


.success-box {
    width: 100%;
    height: 100%;
}


.success-box_01 {
    width: 100%;
    height: 400px;
    display: flex;
    padding: 48px 0px 0px 0px;
    justify-content: space-between;
}


.success-box_02 {
    width: 100%;
    height: 100px;
}


.cases_01_left {
    width: 500px;
    height: 400px;
    border-radius: 100%;
    position: relative;
    transition-duration: 1s;
}


.cases-01 {
    width: 100%;
    height: 130px;
    text-align: center;
    line-height: 130px;
    font-size: 3rem;
    font-weight: 700;
    margin: 0px;
    color: #fff;
    position: absolute;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    top: 10px;
    left: 0px;
}


.cases-left-name {
    display: inline-block;
    width: 400px;
    height: 100px;
    line-height: 100px;
    font-size: 2rem;
    margin: 0px;
    padding: 100px 50px 0px 50px;
    text-align: center;
    letter-spacing: 2px;
    color: #333;
    font-weight: 700;
    transition-duration: 1s;
    letter-spacing: 4px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.cases-right {
    width: 650px;
    height: 300px;
    margin: 50px 25px;
    position: relative;
}


.cases-img {
    width: 650px;
    height: 300px;
}


.home-hover01 {
    width: 650px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.644);
    position: absolute;
    top: 0px;
    left: 0px;
    transition-property: height;
    transition-duration: 1s;
}


.cases-right:hover .home-hover01 {
    height: 300px;
}


.home-hover-text {
    width: 550px;
    height: 200px;
    line-height: 30px;
    font-size: 0.9rem;
    text-align: center;
    color: #fff;
    margin: 50px 50px;
    opacity: 0;
    transition-duration: 1s;
}


.cases-right:hover .home-hover-text {
    opacity: 100%;
    transition-duration: 1.5s;
}


.cases-container {
    width: 400px;
    height: 150px;
    padding: 0px 50px 50px 50px;
}


.cases-text {
    width: 100%;
    height: 30px;
    font-size: 0.9rem;
    text-align: left;
    line-height: 30px;
    color: #333;
    transition-duration: 1s;
}


.cases-right .col-sm-6 {
    width: 650px;
    height: auto;
}


.cases-right .ih-item {
    width: 650px !important;
    height: auto !important;
}


.cases-right .img {
    width: 650px;
    height: 287px;
}


.cases-right .info {
    width: 650px;

}


/* 走马灯 */
.goods_zy {
    width: 1200px;
    height: 300px;
    padding: 50px 0px;
    border-radius: 0px; 
    background-color: #fff;
}
.tu {
    height: 300px;
    width: 200px;
    margin: 0px 50px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff !important;
  width: 300px;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff !important;
  width: 300px;
}
.goods_zy_w {
    width: 800px;
    height: 300px;
    margin: 0 auto;
    background-color: #fff;
}
.text_cp_span {
    display: inline;
    height: 300px;
    line-height: 25px;
    font-size: 0.9rem;
    text-align: left;
    white-space: wrap
}
.el-carousel_container {
    width: 1200px;
}
.el-carousel__item {
    width: 200px;
}
.is-active {
    width: 200px;
}
.goods_pic {
    width: 100%;
    height: 800px;
    padding: 50px 0px;
    background-color: #fff;
} 
.pic-img {
    width: 100%;
    height: auto;
}
.goods-tool_03 {
    height: auto;
    width: 390px;
    padding: 0px;
}

/* 走马灯 */

</style>















