<template>
    <div class="lx-container">
        <div class="lx-box">
            <div class="lx-w">
               <div class="label">
                <div class="pic">
                    <img class="image" src=".././images/tims_new/41501.png" />
                </div>
                <div class="text">
                    <span>TIMS管理系统</span>
                    <span class="del">单击采用定制化服务搭建TIMS管理系统,满足
                    各类库房、各类航材工具的智能化管理</span>
                </div>
               </div>
               <div class="label">
                <div class="pic">
                    <img class="image" src=".././images/tims_new/41502.jpg" />
                </div>
                <div class="text">
                    <span>智能产品设备</span>
                    <span class="del">定制化开发设计，包含自助借还柜、自助工
                    具车、自助充电柜、移动工作站等</span>
                </div>
               </div>
               <div class="label">
                <div class="pic">
                    <img class="image" src=".././images/tims_new/41504.jpeg" />
                </div>
                <div class="text">
                    <span>软硬件设计开发</span>
                    <span class="del">可定制化开发相应的管理后台、移动端设备
                    程序和功能</span>
                </div>
               </div>
            </div>
        </div>
    </div>
</template>
<style>


.lx-container {
    width: 100%;
    height: 400px;
    background-image: url('../images/25.jpg');
    background-size: 100%;
}


.lx-container .lx-box {
    width: 100%;
    height: 100%;
    margin: 0px;
    background-color: rgba(0, 0, 0, 0.6);
}


.lx-w {
    width: calc(100vw - 480px);
    height: 100%;
    margin: 0 240px;
    display: flex;
    justify-content: space-between;
}
.label {
    flex: 1;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
    /* background-color: yellow; */
    /* position: relative; */
}
.pic {
    width: 100%;
    height: 200px;
    border-radius: 100%;
    position: relative;
    
}
.image {
    width: 200px;
    height: 180px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 100%;
    box-shadow: 0px 2px 6px 0px #ffffff;
}
.text {
    box-sizing: border-box;
    /* padding: 0px 70px; */
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
    text-align: center;
}
.del {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    padding: 0px 60px;
}






</style>